export class MobileAppChannel {
  constructor(private name: string) {}

  send(message: string) {
    try {
      const appWnd = (global as any)[this.name];
      appWnd.postMessage(message);
    } catch (e) {
      console.log('it is not mobile app view or we lost context!');
    }
  }

}
