import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {SocialType} from '../domain/Social';
import {credentialApi} from '../api/API';

interface CodeConfirmation {
  code: string;
}

interface EmailConfirmation extends CodeConfirmation{
  email: string;
}

interface PhoneConfirmation extends CodeConfirmation{
  phone: string;
}

interface RequestPayload {
  type: SocialType;
  identity: string;
}

interface ConfirmPayload {
  type: SocialType;
  identity: string;
  confirmation?: EmailConfirmation | PhoneConfirmation;
}

interface ConfirmSocialPayload {
  type: SocialType;
  code: string;
}

export interface CredentialState {
  code: string | null;
}

const initialState: CredentialState = {
  code: null
};

export const credentialSlice = createSlice({
  name: 'credential',
  initialState,
  reducers: {
    setCode: (state, action: PayloadAction<string | null>) => {
      state.code = action.payload;
    }
  }
});

export const {setCode} = credentialSlice.actions;

export const requestCred = createAsyncThunk(
  'request-credential',
  async ({type, identity}: RequestPayload) => {
    await credentialApi.request(type, identity);
  }
);

export const confirm = createAsyncThunk(
  'confirm',
  async ({type, identity, confirmation}: ConfirmPayload) => {
    const {identity: responseIdentity} = await credentialApi.confirm(type, identity, confirmation);

    return responseIdentity;
  }
);

export const confirmSocial = createAsyncThunk(
  'confirm-social',
  async ({type, code}: ConfirmSocialPayload, {dispatch}) => {
    const {payload} = await dispatch(confirm({type, identity: code}));

    return payload;
  }
);
