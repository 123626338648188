import {createTheme, ThemeProvider} from '@mui/material';
import {useAppDispatch, useAppSelector} from '../store/hooks';
import {ErrorView} from './ErrorView';
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import {appConfig} from '../config/config';
import {socials} from '../utils/social';
import {CallbackComponent} from './callbacks/CallbackComponent';

import {SocialPageLayout} from './pages/SocialPage/SocialPageLayout';
import Auth from './pages/Auth';
import {Congratulations} from './pages/SocialPage/Congratulations';
import {useEffect} from 'react';
import {init} from '../store/app';
import {SocialSection} from './pages/SocialPage/SocialSection';
import {SocialRedirect} from './pages/SocialRedirect';
import {ServiceTerms} from './pages/ServiceTerms';
import {PrivacyPolicy} from './pages/PrivacyPolicy';
import {About} from './pages/About';
import {LoaderView} from "./LoaderView";
import {Mobile} from "./pages/Mobile";
import {MobileEmail} from "./pages/MobileEmail";
import {MobilePhone} from './pages/MobilePhone';

const theme = createTheme({
  palette: {
    primary: {main: '#808080'},
    secondary: {main: '#5D4AEE'}
  },
  typography: {fontFamily: 'inherit'}
});
const root = appConfig().ROOT;

function Content() {
  const user = useAppSelector((state) => state.auth.user);
  const initialized = useAppSelector((state) => state.app.initialized);

  if (!initialized) {
    return (<LoaderView/>);
  }

  console.log('search in content opener', window.opener);

  return user ? (
    <BrowserRouter>
      <Routes>
        <Route path={root} element={<SocialPageLayout />}>
          <Route index element={<SocialSection />} />
          <Route path={`${root}congratulations`} element={<Congratulations />} />
          <Route path={`${root}terms`} element={<ServiceTerms />} />
          <Route path={`${root}policy`} element={<PrivacyPolicy />} />
          <Route path={`${root}about`} element={<About />} />
          <Route path={`${root}mobile-flow`} element={<Mobile />} />
          <Route path={`${root}mobile-email`} element={<MobileEmail />} />
          <Route path={`${root}mobile-phone`} element={<MobilePhone />} />
        </Route>

        {socials.map((type) => (
          <Route key={`callback-${type}`} path={`${root}${type}`} element={<CallbackComponent />} />
        ))}

        {socials.map((type) => (
          <Route
            key={`credentials-${type}`}
            path={`${root}request-credential/${type}`}
            element={<SocialRedirect type={type} />}
          />
        ))}
        <Route path="*" element={<Navigate to={root} />} />
      </Routes>
    </BrowserRouter>
  ) : (
    <Auth />
  );
}

export default function Main() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(init());
  }, [dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <ErrorView />
      <Content />
    </ThemeProvider>
  );
}
