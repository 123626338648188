import {Box} from '@mui/material';

import PhoneView from '../SocialPage/views/PhoneView';

export const MobilePhone = () => {
  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <Box
        width="250px"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        textAlign="center"
      >
        <PhoneView />
      </Box>
    </Box>
  );
};
