import {FC, useState} from 'react';
import {AppBar, Box, IconButton, Menu, MenuItem, Toolbar} from '@mui/material';
import {useAppDispatch} from 'store/hooks';
import {AccountCircle} from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import {localize} from 'config/Language';
import {logout} from 'store/auth';
import {Outlet, useNavigate} from 'react-router-dom';

export const SocialPageLayout: FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const toggleMenu = (event: React.MouseEvent<HTMLElement> | null) =>
    setAnchorEl(event?.currentTarget || null);

  const onLogout = () => {
    setAnchorEl(null);
    dispatch(logout());
    navigate('/');
  };

  return (
    <Box>
      <AppBar color="secondary" position="static">
        <Toolbar>
          <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{mr: 2}}>
            <MenuIcon />
          </IconButton>
          <div style={{flexGrow: 1}} />
          <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={toggleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={Boolean(anchorEl)}
              onClose={() => toggleMenu(null)}
            >
              <MenuItem onClick={() => toggleMenu(null)}>Profile</MenuItem>
              <MenuItem onClick={onLogout}>{localize('LOGOUT')}</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Box>
        <Box pt={3} width="100%" textAlign="center">
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};
