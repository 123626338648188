import {dropToken, httpGetJSON, token} from './Fetch';
import {apiPrefix} from '../config/config';

export class AuthApi {
  async getStatus() {
    try {
      const resp = await httpGetJSON(`${apiPrefix()}/auth/status`, token(), {
        ignoreErrors: true
      });

      console.log('resp', resp);
      return resp;
    } catch (e) {
      console.error(e);
    }
  }

  async logout() {
    dropToken();
  }
}
