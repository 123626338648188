export interface Locale {
  [key: string]: string;
}

const EN: Locale = {
  OK: 'Ok',
  CANCEL: 'Cancel',
  SUBMIT: 'Submit',
  UPLOAD: 'Upload',
  EDIT: 'Edit',
  LOADING: 'Loading',
  LOGIN: 'Login',
  LOGOUT: 'Logout',
  PASSWORD: 'Password',
  EMAIL: 'Enter your e-mail here',
  BACK: 'Back',
  NEXT: 'Next',
  FINISH: 'Finish',
  STATUS: 'Status',
  NEW_REQUEST: 'New request',
  CONFIRMATION_CODE: 'Confirmation code',
  CONFIRM: 'Confirm',
  RE_SEND_CODE: 'Resend confirmation code',
  VERIFY_PHONE: 'Verify phone number',
  PHONE: 'Phone number',
  ENTER_PIN_TO_CONFIRM_PHONE: 'Enter PIN from SMS to confirm phone number',
  CONFIRMATION_PIN: 'Confirmation PIN',
  EMAIL_TYPE: 'Continue with email',
  PHONE_TYPE: 'Continue with phone'
};

class LocalesManager {
  private localesSet = new Map<string, Locale>();
  private currentLocale: Locale = EN;
  private currentKey = 'EN';

  init() {
    this.localesSet.set('EN', EN);
    this.currentKey = localStorage.getItem('language') || 'EN';
    this.currentLocale = this.localesSet.get(this.currentKey) || EN;
  }

  localize(key: string, ...params: any[]) {
    let str = this.currentLocale[key] || key;
    if (params && params.length) {
      params.forEach((p, i) => {
        str = str.replace(`{${i}}`, p);
      });
    }
    return str;
  }

  setLocale(locale: string) {
    localStorage.setItem('language', locale);
    this.currentKey = locale;
    this.currentLocale = this.localesSet.get(this.currentKey) || EN;
  }

  get locale() {
    return this.currentLocale;
  }

  get key() {
    return this.currentKey;
  }
}

export const localesManager = new LocalesManager();

export const SUPPORTED_LOCALES: {label: string; value: string}[] = [
  {
    label: 'English',
    value: 'EN'
  }
];

export const setLocale = (locale: string) => {
  localesManager.setLocale(locale);
};

export const localize = (key: string, ...params: any[]) => {
  return localesManager.localize(key, ...params);
};
