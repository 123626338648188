import {ApiError} from '../domain/ApiError';
import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {errorsHandler} from '../config/ErrorsHandler';
import {status} from './auth';

export interface AppState {
  error?: ApiError;
  initialized?: boolean;
}

const initialState: AppState = {
  error: undefined,
  initialized: false
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<ApiError>) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = undefined;
    },
    startInitialization: (state) => {
      state.initialized = false;
    },
    completeInitialization: (state) => {
      state.initialized = true;
    }
  }
});

const {setError, clearError, startInitialization, completeInitialization} = appSlice.actions;

export const init = createAsyncThunk('init', async (_, {dispatch}) => {
  dispatch(startInitialization());
  errorsHandler.setErrorHandler((err: ApiError) => {
    dispatch(setError(err));
    dispatch(init());
  });
  await dispatch(status());
  dispatch(completeInitialization());
});

export const openApiError = (error: ApiError) => (dispatch: any) => {
  dispatch(setError(error));
};

export const closeApiError = () => (dispatch: any) => {
  dispatch(clearError());
};
