import {combineReducers, configureStore} from '@reduxjs/toolkit';
import {authSlice} from './auth';
import {appSlice} from './app';
import {credentialSlice} from './credential';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  persistStore
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import type {} from 'redux-thunk/extend-redux';

const reducers = combineReducers({
  auth: authSlice.reducer,
  app: appSlice.reducer,
  credential: credentialSlice.reducer
});

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['app', 'credential']
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
  ]
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const persistor = persistStore(store);
