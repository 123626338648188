import {Box, Divider, Link as MuiLink, Typography} from '@mui/material';
import {Link} from 'react-router-dom';
import {useState} from 'react';

import EmailView from './views/EmailView';
import {SocialView} from './views/SocialView';
import PhoneView from './views/PhoneView';
import {localize} from '../../../config/Language';

export const SocialSection = () => {
  const [showEmail, setShowEmail] = useState(false);
  const [showPhone, setShowPhone] = useState(false);

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <Box
        width="250px"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        textAlign="center"
      >
        <MuiLink
          sx={{cursor: 'pointer', mb: 1}}
          display="flex"
          alignItems="center"
          justifyContent="center"
          color="secondary"
          onClick={() => setShowEmail(!showEmail)}
        >{localize('EMAIL_TYPE')}</MuiLink>
        {showEmail && (<div style={{paddingBottom: 32}}><EmailView /></div>)}
        <MuiLink
          sx={{cursor: 'pointer', mb: 1}}
          display="flex"
          alignItems="center"
          justifyContent="center"
          color="secondary"
          onClick={() => setShowPhone(!showPhone)}
        >{localize('PHONE_TYPE')}</MuiLink>
        {showPhone && (<div><PhoneView /></div>)}
        <SocialView />
        {/* <Link to="about">Here will be more</Link> */}
        <Divider flexItem sx={{maxWidth: 250, mt: 3}} />
      </Box>

      <Box px={4} py={2} textAlign="center">
        <Typography whiteSpace="pre-line" color="primary" variant="body2" display="block">
          Please use this app to generate credentials.
          {'\n'/*}
          By clicking continue, you agree to our
          {'\n'*/}
          <a href={'https://proofspace.page.link/sspp-desktop'} target={'_blank'}>Privacy Policy</a>
        </Typography>
      </Box>
    </Box>
  );
};
