import {ApiError} from '../domain/ApiError';
import {closeApiError} from '../store/app';
import {useAppDispatch, useAppSelector} from '../store/hooks';
import {Box, IconButton, Snackbar, SnackbarContent} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import CloseIcon from '@mui/icons-material/Close';

interface IProps {
  error?: ApiError;
  close: () => void;
}

export function ErrorView() {
  const error = useAppSelector((state) => state.app.error);
  const dispatch = useAppDispatch();

  if (!error) {
    return null;
  }
  const close = () => dispatch(closeApiError());
  return (
    <Snackbar open={true} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
      <SnackbarContent
        style={{backgroundColor: '#f72d23', color: '#FFFFFF'}}
        message={
          <div>
            <Box display="flex" alignItems="center">
              <Box display="flex" alignItems="center" pr={2}>
                <ErrorIcon />
              </Box>
              <div>{error.error || 'Error!'}</div>
            </Box>
            <div>{error.stackTrace || ''}</div>
          </div>
        }
        action={
          <div>
            <IconButton onClick={close}>
              <CloseIcon style={{color: '#FFFFFF'}} />
            </IconButton>
          </div>
        }
      />
    </Snackbar>
  );
}
