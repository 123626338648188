import {FC, ReactNode, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {appConfig} from 'config/config';

import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import RedditIcon from '@mui/icons-material/Reddit';
import TelegramIcon from '@mui/icons-material/Telegram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import AppleIcon from '@mui/icons-material/Apple';

import DiscordIcon from 'icons/DiscordIcon';
import {Button, Typography} from '@mui/material';
import {CredentialApi} from 'api/CredentialApi';
import {SocialType} from 'domain/Social';
import {socials} from 'utils/social';
import {OAuthPopUp} from "../OAuthPopUp";
import {waitUntil} from "../../../../utils/TimeUtils";
import {getLSItemName} from "../../../../utils/storageUtil";

interface Social {
  icon: ReactNode;
  color: string;
}

type SocialMap = {
  [key in Exclude<SocialType, SocialType.EMAIL>]: Social;
};

const capitalize = (text: string) => text.charAt(0).toUpperCase() + text.slice(1);

const iconProps = {
  fontSize: 'small',
  sx: {color: 'white'}
} as const;
// @ts-ignore
const social: SocialMap = {
  [SocialType.LINKED_IN]: {icon: <LinkedInIcon {...iconProps} />, color: '#0A66C2'},
  [SocialType.DISCORD]: {icon: <DiscordIcon {...iconProps} />, color: '#7289da'},
  //  [SocialType.APPLE]: {icon: <AppleIcon {...iconProps} />, color: '#000000'},
  [SocialType.GIT_HUB]: {icon: <GitHubIcon {...iconProps} />, color: '#000000'},
  [SocialType.GOOGLE]: {icon: <GoogleIcon {...iconProps} />, color: '#4285F4'},
  // [SocialType.FACEBOOK]: {icon: <FacebookIcon {...iconProps} />, color: '#1877F2'},
  //  [SocialType.REDDIT]: {icon: <RedditIcon {...iconProps} />, color: '#FF5700'},
  [SocialType.TWITTER]: {icon: <TwitterIcon {...iconProps} />, color: '#1DA1F2'},
  //  [SocialType.TELEGRAM]: {icon: <TelegramIcon {...iconProps} />, color: '#2AABEE'}
};

interface SocialButtonProps {
  type: Exclude<SocialType, SocialType.EMAIL>;
  onReceived: (type: SocialType, identity: string) => void;
}

let isRequestOngoing = false;
const root = appConfig().ROOT;

export const SocialView = () => {
  const navigate = useNavigate();

  const onReceived = (type: SocialType, identity: string) => {
    navigate(`${root}congratulations?type=${type}&identity=${identity}`);
  };

  const receiveMessageFromPopup = ({data}: any) => {
    console.log('I receive message in window!!!', data);
    if (data.identity) {
      navigate(`${root}congratulations?type=${data.type}&identity=${data.identity}`);
    }
  };

  useEffect(() => {
    const effect = async () => {
      isRequestOngoing = true;
      window.addEventListener('message', receiveMessageFromPopup);
      isRequestOngoing = false;
    };

    if (!isRequestOngoing) {
      effect();
    }

    return () => {
      window.removeEventListener('message', receiveMessageFromPopup);
    };
  }, []);

  return (
    <>
      <Typography color="primary" variant="body2" display="block" sx={{my: 2}}>
        OR
      </Typography>
      {socials.map((type) => (
        <SocialButton key={`button-${type}`} type={type} onReceived={onReceived}/>
      ))}
    </>
  );
};

const SocialButton: FC<SocialButtonProps> = ({type, onReceived}) => {
  const {icon, color} = social[type];
  // const [opened, setOpened] = useState<boolean>(false);
  // const [src, setSrc] = useState<string>('');

  const onClick = async () => {
    // localStorage.removeItem(getLSItemName(type));
    const {oAuthUrl} = await CredentialApi.getOAuthUrl(type);
    window.location.replace(oAuthUrl);
    // setSrc(oAuthUrl);
    // setOpened(true);
    /*const popupWidth = 600;
    const popupHeight = 400;
    const left = (window.screen.width - popupWidth) / 2;
    const top = (window.screen.height - popupHeight) / 2;
    const params =
      'width=' + popupWidth + ', height=' + popupHeight + ', left=' + left + ', top=' + top;// + ', popup=true';
    window.open(oAuthUrl, '_blank', params);
    let identity: string | undefined;
    await waitUntil(3000, async () => {
      const r = await CredentialApi.isConfirmed(type);
      identity = r.identity;
      return !!identity;
    });
    if (identity) {
      onReceived(type, identity);
    }*/
    /*console.log(wnd);
    if (wnd) {
      console.log('is closed?', wnd.closed);
      await waitUntil(100, () => {
        console.log('is closed now?', wnd.closed);
        return !wnd || wnd.closed;
      });
      console.log('i wait when it closed!');
      const identity = localStorage.getItem(getLSItemName(type));
      console.log('identity is', identity);
      if (identity) {
        localStorage.removeItem(getLSItemName(type));
        onReceived(type, identity);
      }
    }*/
  };
  // const onClose = () => setOpened(false);

  return (
    <Button
      key={type}
      onClick={onClick}
      sx={{
        backgroundColor: color,
        textTransform: 'none',
        justifyContent: 'start',
        mb: 1.5,
        gap: 0.5
      }}
      size="large"
      variant="contained"
      startIcon={icon}
    >
      <Typography sx={{width: '100%'}} textAlign="center" variant="body1">
        {`Continue with ${capitalize(type)}`}
      </Typography>
    </Button>
  );
};
