import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useAppDispatch} from 'store/hooks';
import {appConfig} from 'config/config';
import {CredentialApi} from "../../../api/CredentialApi";
import {queryToObject} from "../../../utils/UrlUtil";
import {SocialType} from "../../../domain/Social";

let isRequestOngoing = false;

export const Mobile = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const effect = async (credentialId: string) => {
    console.log('mobile, credential', credentialId);
    // localStorage.removeItem(getLSItemName(type));
    isRequestOngoing = true;
    const {type, oAuthUrl} = await CredentialApi.getMobileOAuthUrl(credentialId);
    isRequestOngoing = false;
    console.log('oauth url', oAuthUrl, type);
    // return;
    if (type === SocialType.EMAIL) {
      navigate(`${appConfig().ROOT}mobile-email`);
    } else if (type === SocialType.PHONE) {
      navigate(`${appConfig().ROOT}mobile-phone`);
    } else {
      window.location.replace(oAuthUrl);
    }
    // navigate(oAuthUrl);
  };

  useEffect(() => {
    if (window.location.search && !isRequestOngoing) {
      const params = queryToObject(window.location.search);
      console.log('params', params);
      const {credentialId} = params;
      credentialId ? effect(credentialId as string) : navigate(appConfig().ROOT);
    }
  });

  return (<div></div>);
};
