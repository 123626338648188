import {MobileAppChannel} from "../utils/MobileAppChannel";

export interface ConfigData {
  readonly SERVICE_DID: string;
  readonly OAUTH_CLIENT_ID: string;
  readonly OAUTH_ACTION_ID: string;
  readonly OAUTH_INSTANCE_ID: string;
  readonly BACKEND: string;
  readonly CONTAINER_HOST: string;
  readonly HTTP_PROTOCOL: string;
  readonly WS_PROTOCOL: string;
  readonly ROOT: string;
  readonly FRONT_HOST: string;
  readonly ENV: string;
}

class NonPKGConfig implements ConfigData {
  readonly SERVICE_DID: string = process.env.REACT_APP_SERVICE_DID || '2RjEBo9zXkJByXm14xQHtg';
  readonly OAUTH_CLIENT_ID: string = process.env.REACT_APP_OAUTH_CLIENT_ID || '6a100ca9-3f8d-4441-b344-cf6e407c092e';
  readonly OAUTH_ACTION_ID: string = process.env.REACT_APP_OAUTH_ACTION_ID || '50';
  readonly OAUTH_INSTANCE_ID: string =
    process.env.REACT_APP_OAUTH_INSTANCE_ID || '6236e827eee2e4ab2c0ea5a9';
  readonly BACKEND: string = process.env.REACT_APP_BACKEND || 'http://127.0.0.1:3080';
  readonly CONTAINER_HOST: string =
    process.env.REACT_APP_CONTAINER_HOST || 'test.proofspace.id/service-dashboard-backend';
  readonly HTTP_PROTOCOL: string = process.env.REACT_APP_HTTP_PROTOCOL || 'https';
  readonly WS_PROTOCOL: string = process.env.REACT_APP_WS_PROTOCOL || 'wss';
  readonly ROOT: string = process.env.REACT_APP_ROOT_PATH || '/';
  readonly FRONT_HOST: string = process.env.REACT_APP_FRONT_HOST || 'test.proofspace.id';
  readonly ENV: string = process.env.REACT_APP_ENV || 'test';
}

export const servicePrefix = () =>
  `${appConfig().HTTP_PROTOCOL}://${appConfig().CONTAINER_HOST}/service/${appConfig().SERVICE_DID}`;

export const apiPrefix = () => `${appConfig().BACKEND}/${appConfig().SERVICE_DID}`;

export const nativeAppPrefix = () => `${appConfig().HTTP_PROTOCOL}://${appConfig().FRONT_HOST}/native/execute/}`;

export const appInPkg = process.env.REACT_APP_IN_PKG;

let _appConfig: ConfigData | undefined;

export const appConfig = () => {
  if (_appConfig === undefined) {
    throw new Error('Attempt to use appConfig which is not defined');
  }
  return _appConfig;
};

export const isAppConfigLoaded = () => !!_appConfig;

export const setAppConfig = (cfg: ConfigData) => {
  _appConfig = cfg;
};

if (!appInPkg) {
  console.log('!appInPkg,  use non-packaged config.');
  _appConfig = new NonPKGConfig();
} else {
  console.log('should use package config');
}

export const sendToMobileApp = (msg: string) => {
  console.log('We send message to mobile app', msg);
  new MobileAppChannel('nativeExecution').send(msg);
};

export const PKG_CONFIG_URL = process.env.REACT_APP_PKG_CONFIG_URL;
