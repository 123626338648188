import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {authApi} from '../api/API';
import {setToken} from '../api/Fetch';

interface User {
  clientDid: string;
}

export interface AuthState {
  user?: User;
}

const initialState: AuthState = {
  user: undefined
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    dropUser: (state) => {
      state.user = undefined;
    }
  }
});

const {setUser, dropUser} = authSlice.actions;

export const status = createAsyncThunk('status', async (_, {dispatch}) => {
  const user = await authApi.getStatus();
  dispatch(setUser(user));
});

export const logout = createAsyncThunk('logout', async (_, {dispatch}) => {
  await authApi.logout();
  dispatch(dropUser());
});

export const updateToken = createAsyncThunk('update-token', async (token: string, {dispatch}) => {
  setToken(token);
  await dispatch(status());
});
