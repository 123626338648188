import RedditIcon from '@mui/icons-material/Reddit';
import TelegramIcon from '@mui/icons-material/Telegram';

import AppleIcon from '@mui/icons-material/Apple';

import {Button, Typography} from '@mui/material';
import {CredentialApi} from 'api/CredentialApi';
import {SocialType} from 'domain/Social';
import {socials} from 'utils/social';
import {FC, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {appConfig} from 'config/config';

const capitalize = (text: string) => text.charAt(0).toUpperCase() + text.slice(1);

const iconProps = {
  fontSize: 'small',
  sx: {color: 'white'}
} as const;

type SocialButtonProps = {
  type: SocialType;
};

const social = {
  //  [SocialType.APPLE]: {icon: <AppleIcon {...iconProps} />, color: '#000000'},
  //  [SocialType.REDDIT]: {icon: <RedditIcon {...iconProps} />, color: '#FF5700'},
  //  [SocialType.TELEGRAM]: {icon: <TelegramIcon {...iconProps} />, color: '#2AABEE'}
};

let isRequestOngoing = false;
const root = appConfig().ROOT;

export const About = () => {
  const navigate = useNavigate();

  const receiveMessageFromPopup = ({data}: any) => {
    if (data.identity) {
      navigate(`${root}congratulations?type=${data.type}&identity=${data.identity}`);
    }
  };

  useEffect(() => {
    const effect = async () => {
      isRequestOngoing = true;
      window.addEventListener('message', receiveMessageFromPopup);
      isRequestOngoing = false;
    };

    if (!isRequestOngoing) {
      effect();
    }

    return () => {
      window.removeEventListener('message', receiveMessageFromPopup);
    };
  }, []);

  return (
    <>
      <Typography fontWeight={500} variant="body2" sx={{my: 1}}>
        OR
      </Typography>
      {socials.map((type) => (
        <SocialButton key={`button-${type}`} type={type} />
      ))}
    </>
  );
};

const SocialButton: FC<SocialButtonProps> = ({type}) => {
  //  @ts-ignore
  const {icon, color} = social[type];

  const onClick = async (type: SocialType) => {
    const {oAuthUrl} = await CredentialApi.getOAuthUrl(type);
    const popupWidth = 600;
    const popupHeight = 400;
    const left = (window.screen.width - popupWidth) / 2;
    const top = (window.screen.height - popupHeight) / 2;
    const params =
      'width=' + popupWidth + ', height=' + popupHeight + ', left=' + left + ', top=' + top;
    window.open(oAuthUrl, '_blank', params);
  };

  return (
    <Button
      key={type}
      onClick={() => onClick(type)}
      sx={{
        backgroundColor: color,
        textTransform: 'none',
        justifyContent: 'start',
        mb: 1.5,
        gap: 0.5
      }}
      size="large"
      variant="contained"
      startIcon={icon}
    >
      <Typography sx={{width: '100%'}} textAlign="center" variant="body1">
        {`Continue with ${capitalize(type)}`}
      </Typography>
    </Button>
  );
};
