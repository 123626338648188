import {Box} from '@mui/material';

import EmailView from '../SocialPage/views/EmailView';

export const MobileEmail = () => {
  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <Box
        width="250px"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        textAlign="center"
      >
        <EmailView />
      </Box>
    </Box>
  );
};
