import {httpGetJSON, httpPostJSON, token} from './Fetch';
import {SocialType} from '../domain/Social';
import {apiPrefix} from '../config/config';

export class CredentialApi {
  async request(type: SocialType, identity: string) {
    const resp = await httpPostJSON(
      `${apiPrefix()}/credentials/request/${type}`,
      {identity},
      token()
    );
    return resp;
  }

  async confirm<T>(type: SocialType, identity: string, confirmation?: T) {
    const resp = await httpPostJSON(
      `${apiPrefix()}/credentials/confirm/${type}`,
      {identity, confirmation},
      token()
    );
    return resp;
  }

  static async getOAuthUrl(social: SocialType) {
    const response = await httpGetJSON(`${apiPrefix()}/credentials/oauth/${social}`, token());
    return response;
  }

  static async getMobileOAuthUrl(credentialId: string) {
    const response = await httpGetJSON(`${apiPrefix()}/credentials/mobile-oauth/${credentialId}`, token());
    return response;
  }

  static async isConfirmed(social: SocialType) {
    const response = await httpGetJSON(`${apiPrefix()}/credentials/is-confirmed/${social}`, token());
    return response;
  }
}
