import {useEffect, useState} from 'react';
import * as ProofSpace from 'ssi-auth-lib';
import {setToken} from 'api/Fetch';
import {Box, Button, Paper} from '@mui/material';
import {appConfig} from 'config/config';
import {useAppDispatch} from 'store/hooks';
import {status} from 'store/auth';
import {isMobile} from "ssi-auth-lib";
import logo from 'LogoPS.png';
import {wait} from "../../../utils/TimeUtils";

const ELEMENT = 'ssiOAuthElem';

export default function Auth() {
  const dispatch = useAppDispatch();
  const [expired, setExpired] = useState(false);

  const startAuth = () => {
    const elem = document.getElementById(ELEMENT) as HTMLDivElement;

    if (elem) {
      let env = ProofSpace.ENV.PROD;
      if (appConfig().ENV === 'test') {
        env = ProofSpace.ENV.TEST;
      } else if (appConfig().ENV === 'stage') {
        env = ProofSpace.ENV.STAGE;
      }
      ProofSpace.WebLinker.stop();
      ProofSpace.WebLinker.startWithSSIOAuth(elem, {
        env,
        clientId: appConfig().OAUTH_CLIENT_ID,
        serviceDid: appConfig().SERVICE_DID,
        interactionId: appConfig().OAUTH_ACTION_ID,
        instanceId: appConfig().OAUTH_INSTANCE_ID,
        size: 300,
        button: {
          text: 'Continue',
          class: 'psBtn'
          // style: 'height: 64px; background-color: #5D4AEE; border-color: #5D4AEE; border: 1px; color: #FFFFFF; padding: 16px; font-size: 16px'
        },
        loadingButton: {
          class: 'psBtn loadingPsBtn'
          // style: 'height: 64px; background-color: #5D4AEE; border-color: #5D4AEE; border: 1px; color: #FFFFFF; padding: 16px; font-size: 16px; opacity: 0.3'
        },
        timer: {
          style: 'padding-top: 8px'
        }
      }).then((result) => {
        console.log('result is', result);
        if (!result) {
          setExpired(true);
        } else {
          setToken(result.access_token);
          dispatch(status());
        }
      }).catch((e) => setExpired(true));
    }
  };

  useEffect(() => {
    startAuth();
  }, [dispatch]);

  const tryAgain = async () => {
    setExpired(false);
    await wait(10);
    startAuth();
  };

  const serviceName = 'ProofSpace Social Stamps';

  const mobileText = () => {
    return (
      <div>
        To authorize with <strong>{serviceName}</strong>, tap "Continue", then proceed in the app and return to this page.<br/>
        <br/>
        We will attempt to bring you back to this page automatically, but please note that this process may fail.
      </div>
    );
  };

  const desktopText = () => {
    return (
      <div>
        To authorize with <strong>{serviceName}</strong>, scan the QR code, follow the instructions in the ProofSpace app, and then return to this page.
      </div>
    );
  }

  return (
    <Box
      style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
      }}
    >
      <Paper style={{width: isMobile() ? 'auto' : 600, padding: 16, minWidth: 250}}>
          <div style={{flexDirection: 'row', display: 'flex', flexWrap: 'nowrap', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{minWidth: 48, minHeight: 48}}>
              <img
                style={{maxWidth: 48, maxHeight: 48, minWidth: 48, minHeight: 48}}
                src={logo}
                alt={''}
              />
            </div>
            <div style={{paddingLeft: 16, fontSize: 18, fontWeight: 600}}>
              ProofSpace SSI OAuth
            </div>
          </div>
        <div style={{paddingTop: 16}}>
          {isMobile() ? mobileText() : desktopText()}
        </div>
        <div style={{padding: 32}}>
          {
            expired ?
              (
                <div>
                  <div style={{paddingBottom: 8, color: '#990202'}}>
                    Auth Session is expired...
                  </div>
                  <div>
                    <Button variant={'outlined'} className={'psBtn'} onClick={tryAgain}>Try again</Button>
                  </div>
                </div>
              ) :
              (<div><div id={ELEMENT}></div></div>)
          }
        </div>
      </Paper>
    </Box>
  );
}
