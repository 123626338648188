export enum SocialType {
  EMAIL = 'email',
  PHONE = 'phone',
  GOOGLE = 'google',
  // FACEBOOK = 'facebook',
  TWITTER = 'twitter',
  //  REDDIT = 'reddit',
  //  TELEGRAM = 'telegram',
  LINKED_IN = 'linkedIn',
  //  APPLE = 'apple',
  GIT_HUB = 'github',
  DISCORD = 'discord'
}
