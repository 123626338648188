import {ChangeEvent, FC, useState} from 'react';
import {useAppDispatch} from 'store/hooks';
import {Box, Button, TextField, Typography} from '@mui/material';
import {localize} from 'config/Language';
import {confirm, requestCred} from 'store/credential';
import {SocialType} from 'domain/Social';
import {useNavigate} from 'react-router-dom';
import {appConfig} from 'config/config';

const isPhoneValid = (phone: string) => {
  return /^\+[0-9]{12}$/.test(phone);
};

const root = appConfig().ROOT;

const PhoneView: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isPhoneView, setIsPhoneView] = useState(true);

  const [phone, setPhone] = useState<string>('');
  const [code, setCode] = useState<string>('');

  const onRequest = () => {
    dispatch(requestCred({type: SocialType.PHONE, identity: phone}));
    setIsPhoneView(false);
  };
  const onConfirm = async () => {
    try {
      const {payload: identity} = await dispatch(
        confirm({
          type: SocialType.PHONE,
          identity: phone,
          confirmation: {
            phone,
            code
          }
        })
      );
      setIsPhoneView(true);
      setCode('');
      setPhone('');
      if (identity) {
        navigate(`${root}congratulations?type=${SocialType.PHONE}&identity=${identity}`);
      }
    } catch {
      //do nothing
    }
  };

  const onPhoneChange = (event: ChangeEvent<HTMLInputElement>) => {
    const val: string = event.target.value;
    setPhone(val.trim());
  };
  const onCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const val: string = event.target.value;
    setCode(val.trim());
  };

  if (isPhoneView) {
    const hasErrors = !isPhoneValid(phone);
    console.log('has errors?', hasErrors);
    return (
      <Box display="flex" flexDirection="column" gap={2}>
        <Typography fontWeight={600} variant="body1" align="left">
          {localize('VERIFY_PHONE')}:
        </Typography>
        <TextField
          size="small"
          label={localize('PHONE')}
          placeholder={'+639000000000'}
          error={hasErrors && phone.length > 0}
          variant="outlined"
          value={phone}
          onChange={onPhoneChange}
        />
        <Button color="secondary" variant={'contained'} onClick={onRequest} disabled={!phone || hasErrors}>
          {localize('REQUEST')}
        </Button>
      </Box>
    );
  }
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Typography fontWeight={600} variant="body1" align="left">
        {localize('ENTER_PIN_TO_CONFIRM_PHONE')}
      </Typography>
      <TextField
        label={localize('CONFIRMATION_PIN')}
        variant="outlined"
        value={code}
        onChange={onCodeChange}
      />
      <div>
        <Button color="secondary" variant={'contained'} onClick={onConfirm} disabled={!code}>
          {localize('CONFIRM')}
        </Button>
      </div>
      <div>
        <Button color="primary" variant={'contained'} onClick={onRequest} disabled={!phone}>
          {localize('RE_SEND_CODE')}
        </Button>
      </div>
    </Box>
  );
}

export default PhoneView;
