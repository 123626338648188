import {ChangeEvent, useState} from 'react';
import {useAppDispatch} from 'store/hooks';
import {Box, Button, TextField, Typography} from '@mui/material';
import {localize} from 'config/Language';
import {confirm, requestCred} from 'store/credential';
import {SocialType} from 'domain/Social';
import {useNavigate} from 'react-router-dom';
import {appConfig} from 'config/config';

const isEmailValid = (email: string) => {
  return /[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(email);
};

const root = appConfig().ROOT;

export default function EmailView() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isEmailView, setEmailView] = useState(true);

  const [email, setEmail] = useState<string>('');
  const [code, setCode] = useState<string>('');

  const onRequest = () => {
    dispatch(requestCred({type: SocialType.EMAIL, identity: email}));
    setEmailView(false);
  };
  const onConfirm = async () => {
    try {
      const {payload: identity} = await dispatch(
        confirm({
          type: SocialType.EMAIL,
          identity: email,
          confirmation: {
            email,
            code
          }
        })
      );
      setEmailView(true);
      setCode('');
      setEmail('');
      if (identity) {
        navigate(`${root}congratulations?type=${SocialType.EMAIL}&identity=${identity}`);
      }
    } catch {
      //do nothing
    }
  };

  const onChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    const val: string = event.target.value;
    setEmail(val.trim());
  };
  const onChangeCode = (event: ChangeEvent<HTMLInputElement>) => {
    const val: string = event.target.value;
    setCode(val.trim());
  };

  if (isEmailView) {
    const hasErrors = !isEmailValid(email);
    console.log('has errors?', hasErrors);
    return (
      <Box display="flex" flexDirection="column" gap={2}>
        <Typography fontWeight={600} variant="body1" align="left">
          Verify e-mail:
        </Typography>
        <TextField
          size="small"
          label={localize('EMAIL')}
          error={hasErrors && email.length > 0}
          variant="outlined"
          value={email}
          onChange={onChangeEmail}
        />
        <Button color="secondary" variant={'contained'} onClick={onRequest} disabled={!email || hasErrors}>
          {localize('REQUEST')}
        </Button>
      </Box>
    );
  }
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Typography fontWeight={600} variant="body1" align="left">
        Enter code from mail:
      </Typography>
      <TextField
        label={localize('CONFIRMATION_CODE')}
        variant="outlined"
        value={code}
        onChange={onChangeCode}
      />
      <div>
        <Button color="secondary" variant={'contained'} onClick={onConfirm} disabled={!code}>
          {localize('CONFIRM')}
        </Button>
      </div>
      <div>
        <Button color="primary" variant={'contained'} onClick={onRequest} disabled={!email}>
          {localize('RE_SEND_CODE')}
        </Button>
      </div>
    </Box>
  );
}
