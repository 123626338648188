import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {isAndroid} from 'ssi-auth-lib';
import {confirmSocial} from 'store/credential';
import {useAppDispatch} from 'store/hooks';
import {appConfig, nativeAppPrefix, sendToMobileApp} from 'config/config';
import {SocialType} from 'domain/Social';
import {queryToObject} from "../../utils/UrlUtil";

const root = appConfig().ROOT;
let isRequestOngoing = false;

export const CallbackComponent = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const effect = async (type: SocialType, code: string) => {
    console.log('type', type, 'code', code);
    // localStorage.removeItem(getLSItemName(type));
    isRequestOngoing = true;
    try {
      const {payload: identity} = await dispatch(confirmSocial({type, code}));
      isRequestOngoing = false;
      console.log('identity', identity);

      console.log('opener', window.opener, window.parent);
      console.log('i have identity', type, identity);
      sendToMobileApp('CloseMeFromPopUpWindow');
      try {
        navigate(`${root}congratulations?type=${type}&identity=${identity}`);
        /*if (window.opener) {
          window.close();
        } else *//*if (type === SocialType.FACEBOOK && isAndroid()) {
          window.location.replace(nativeAppPrefix());
        } else {
          navigate(`${root}congratulations?type=${type}&identity=${identity}`);
        }*/
      } catch (e) {
        console.log('can not close window, try to navigate');
        // window.location.href = `${root}congratulations?type=${type}&identity=${identity}`;
        navigate(`${root}congratulations?type=${type}&identity=${identity}`);
      }
    } catch (e) {
      console.log(e);
      return;
    }
  };

  useEffect(() => {
    if (window.location.search && !isRequestOngoing) {
      const params = queryToObject(window.location.search);
      const isSocialState = Object.values(SocialType).includes(params.state as SocialType);
      console.log('params', params, isSocialState);

      if (isSocialState) {
        const {code} = params;
        code ? effect(params.state as SocialType, code as string) : navigate(appConfig().ROOT);
      }
    }
  });

  return (<div></div>);
};
