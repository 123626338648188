export const wait = async (delay: number) =>
  new Promise<void>((resolve) => {
    setTimeout(resolve, delay);
  });

export const waitUntil = async (delay: number, condition: () => Promise<boolean>) => {
  while (!(await condition())) {
    console.log('condition is not true. waiting...');
    await wait(delay);
  }
};

/*export class TimerListener {
  constructor(private condition: () => boolean, private handler: () => void) {
  }

  async listen(delay: number) {
    while (!this.condition()) {
      await wait(delay);
    }
    this.handler();
  }
}*/
