import {useNavigate, useSearchParams} from 'react-router-dom';

import {Box, Button} from '@mui/material';
import {appConfig, sendToMobileApp} from 'config/config';
import {localize} from 'config/Language';

const root = appConfig().ROOT;

export const Congratulations = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  sendToMobileApp('CloseMeNow');
  sendToMobileApp('CloseMeFromPopUpWindow');
  return (
    <Box>
      <h2>{localize('CONGRATULATIONS!')}</h2>
      <Box mb={2}>
        Your {searchParams.get('type')} account {searchParams.get('identity')} is confirmed!
      </Box>
      <Button color="secondary" variant={'contained'} onClick={() => navigate(root)}>
        {localize('NEW_REQUEST')}
      </Button>
    </Box>
  );
};
