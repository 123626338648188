import {FC, useCallback, useEffect} from 'react';
import {CredentialApi} from 'api/CredentialApi';
import {SocialType} from 'domain/Social';

type Props = {type: SocialType};

let isRequestOngoing = false;
export const SocialRedirect: FC<Props> = ({type}) => {
  const effect = useCallback(async () => {
    isRequestOngoing = true;
    const {oAuthUrl} = await CredentialApi.getOAuthUrl(type);
    window.open(oAuthUrl, '_self', 'width=600,height=600');
  }, [type]);

  useEffect(() => {
    if (!isRequestOngoing) {
      effect();
    }

    return () => {
      isRequestOngoing = false;
    };
  }, [effect]);

  return <div />;
};
